import React from 'react';
import { Button } from "@/Components/ui/button";
import { Link } from "@inertiajs/react";
import { MapPin, PawPrint } from 'lucide-react';

interface FieldProps {
    id: number;
    image: string;
    name: string;
    what3words: string;
    price: number;
    link: string;
    from: string;
}

interface FieldsGridProps {
    fields: FieldProps[];
}

const cdnURL = 'https://walkies.nyc3.cdn.digitaloceanspaces.com/';

const Field: React.FC<FieldProps> = ({ image, name, what3words, price, link, id, from }) => (
    <div className="bg-white cursor-pointer hover:bg-gray-100 rounded-lg shadow-md overflow-hidden">
        <img src={`${cdnURL}${image}`} alt={name} className="w-full h-48 object-cover" />
        <div className="p-4">
            <Link href={link} className="block mb-2">
                <h3 className="text-xl font-semibold hover:text-brand transition-colors">{name}</h3>
            </Link>
            <div className="text-lg font-bold mb-4">From &pound;{from}</div>
            <Link href={link}>
                <Button className="w-full bg-brand">
                    <PawPrint className="mr-2" />
                    Book Now
                </Button>
            </Link>
        </div>
    </div>
);

const FieldsGrid: React.FC<FieldsGridProps> = ({ fields }) => {
    return (
        <section className="pt-16 px-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
                    Our Fields
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {fields.map((field, index) => (
                        <Field
                            key={index}
                            image={field.image}
                            name={field.name}
                            what3words={field.what3words}
                            price={field.price}
                            link={field.link}
                            id={field.id}
                            from={field.from}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FieldsGrid;